<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div v-if="message_type != 'mysdgs'" class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>MY {{ this.messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>MY {{ this.messagetype.title_jp }}</h2>
      <div class="mascot_wrap" style="display: none">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
      <div
        class="user_img"
        :style="{ backgroundImage: 'url(' + profileImg + ')' }"
        v-if="profileImg"
      ></div>
      <div
        class="user_img"
        :style="{
          backgroundImage:
            'url(' +
            require('@/assets/front_component/images/test/test01.png') +
            ')',
        }"
        v-else
      ></div>
    </div>
  </div>
  <main>
    <div v-if="message_type === 'mysdgs'">
      <SdgsGoalRatio />
    </div>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link
                :to="{
                  name: 'Control message',
                  params: { type: message_type },
                  query: {
                    row_count: this.$route.query.row_count
                      ? this.$route.query.row_count
                      : 25,
                  },
                }"
                >My {{ this.messagetype.title_jp }}一覧</router-link
              >
            </li>
            <li><span>編集</span></li>
          </ul>
        </div>
        <Form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit="update(editmessage.id)"
          v-slot="{ errors }"
        >
          <div class="content">
            <div class="innar">
              <div class="form_outwrap">
                <div v-if="messagecolumn['thanks_type']" class="form_outwrap">
                  <p class="ttl_03">{{ messagecolumn["thanks_type"] }}</p>
                  <ErrorMessage class="each_msg" name="thanks_type" />
                  <div class="wrap m_r70">
                    <div class="in_wrap">
                      <label class="chk_wrap">
                        <Field
                          name="thanks_type"
                          type="checkbox"
                          class="chkbox"
                          v-model="editmessage.thanks_type"
                          value="サンクス"
                        /><span></span><span class="chktxt">サンクス</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap m_r70">
                    <div class="in_wrap">
                      <label class="chk_wrap">
                        <Field
                          name="thanks_type"
                          type="checkbox"
                          class="chkbox"
                          v-model="editmessage.thanks_type"
                          value="ホメロン"
                        /><span></span><span class="chktxt">ホメロン</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    messagecolumn['tags'] &&
                    message_type != 'column' &&
                    message_type != 'photolog' &&
                    message_type != 'mysdgs'
                  "
                  class="form_outwrap"
                >
                  <p class="ttl_03">{{ messagecolumn["tags"] }}</p>
                  <ErrorMessage class="each_msg" name="tags" />
                  <div
                    v-for="taglist in this.taglists.slice()"
                    :key="taglist.id"
                    class="wrap m_r70"
                  >
                    <div class="in_wrap">
                      <label class="chk_wrap">
                        <Field
                          name="tags"
                          type="checkbox"
                          class="chkbox"
                          v-model="editmessage.tags"
                          v-bind:value="taglist.id"
                        /><span></span
                        ><span class="chktxt">{{ taglist.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div v-if="messagecolumn['photolog']" class="wrap wid_100per">
                  <p class="ttl_03">{{ messagecolumn["photolog"] }}</p>
                  <div class="in_wrap">
                    <img id="img_tag_id" style="width: 100%" />
                  </div>
                </div>
                <div v-if="messagecolumn['sdgs_type']" class="wrap wid_100per">
                  <p class="ttl_03">{{ messagecolumn["sdgs_type"] }}</p>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        required=""
                        name="sdgs_type"
                        as="select"
                        v-model="editmessage.sdgs_type"
                        :rules="isRequired"
                      >
                        <option value="2.飢餓をゼロ">2.飢餓をゼロ</option>
                        <option value="3.すべての人に健康と福祉を">
                          3.すべての人に健康と福祉を
                        </option>
                        <option value="6.安全な水とトイレを世界中に">
                          6.安全な水とトイレを世界中に
                        </option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="sdgs_type" />
                  </div>
                </div>

                <div
                  v-if="messagecolumn['sdgs_option']"
                  class="wrap wid_100per"
                >
                  <p class="ttl_03">
                    {{ messagecolumn["sdgs_option"] }}
                  </p>
                  <div class="in_wrap" id="attached_area">
                    <div class="attached_wrap">
                      <div class="select_wrap attached_name_btn">
                        <Field
                          required=""
                          name="sdgs_option"
                          as="select"
                          v-model="editmessage.sdgs_option"
                          @change="changeYear($event)"
                        >
                          <option value="2022">49期（2022)</option>
                          <option value="2023">50期（2023)</option>
                          <option value="2024">51期（2024)</option>
                          <option value="2025">52期（2025)</option>
                          <option value="2026">53期（2026)</option>
                        </Field>
                      </div>
                      <div
                        style="display: none"
                        class="select_wrap attached_fire_btn"
                      >
                        <select
                          name="list_type_select"
                          v-model="editmessage.month"
                          @change="changeMonth($event)"
                        >
                          <option value="">未設定</option>
                          <option value="通年" :data-year="currentYear">
                            通年
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>

                          <option
                            value="10"
                            :data-year="
                              currentYear - 1 != '' && currentYear != 0
                                ? currentYear - 1
                                : ''
                            "
                          >
                            10月
                            {{
                              currentYear - 1 != "" && currentYear != 0
                                ? "(" + (currentYear - 1) + ")"
                                : ""
                            }}
                          </option>
                          <option
                            value="11"
                            :data-year="
                              currentYear - 1 != '' && currentYear != 0
                                ? currentYear - 1
                                : ''
                            "
                          >
                            11月
                            {{
                              currentYear - 1 != "" && currentYear != 0
                                ? "(" + (currentYear - 1) + ")"
                                : ""
                            }}
                          </option>
                          <option
                            value="12"
                            :data-year="
                              currentYear - 1 != '' && currentYear != 0
                                ? currentYear - 1
                                : ''
                            "
                          >
                            12月
                            {{
                              currentYear - 1 != "" && currentYear != 0
                                ? "(" + (currentYear - 1) + ")"
                                : ""
                            }}
                          </option>

                          <option value="1" :data-year="currentYear">
                            1月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="2" :data-year="currentYear">
                            2月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="3" :data-year="currentYear">
                            3月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="4" :data-year="currentYear">
                            4月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="5" :data-year="currentYear">
                            5月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="6" :data-year="currentYear">
                            6月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="7" :data-year="currentYear">
                            7月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="8" :data-year="currentYear">
                            8月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="9" :data-year="currentYear">
                            9月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="messagecolumn['title'] && message_type != 'mysdgs'" class="wrap wid_100per">
                  <p class="ttl_03">{{ messagecolumn["title"] }}</p>
                  <div class="in_wrap">
                    <Field
                      name="title"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.title }"
                      v-model="editmessage.title"
                      :rules="isRequired"
                    />
                    <ErrorMessage class="each_msg" name="title" />
                  </div>
                </div>


                <!-- mysdgs -->
                <template v-if="message_type == 'mysdgs'">
                  <div
                    class="form_outwrap"
                  >
                    <p class="ttl_03">{{ messagecolumn["tags"] }}</p>
                    <div
                      v-for="taglist in this.taglists"
                      :key="taglist.id"
                      class="wrap m_r70"
                    >
                      <div class="in_wrap">
                        <label class="chk_wrap">
                          <Field
                            name="tags"
                            type="radio"
                            class="radiobox"
                            v-model="editmessage.tags"
                            v-bind:value="taglist.id"
                            @click="goalCheckBox(taglist.id)"
                          /><span></span
                          ><span class="chktxt chktag">{{ taglist.name }}</span>
                        </label>
                      </div>                   
                    </div>
                  </div>

                  <div v-if="isSdgsOtherType == true" class="wrap wid_100per">
                    <div class="in_wrap">
                      <Field
                        name="title"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.title }"
                        v-model="sdgsGoaltitle"
                        :disabled=checkedGoal
                      />
                    </div>
                  </div>
                </template>
                <!-- mysdgs -->

                <div v-if="messagecolumn['content']" class="wrap wid_100per">
                  <p class="ttl_03">{{ messagecolumn["content"] }}</p>
                  <div class="in_wrap">
                    <ckeditor
                      :editor="editor"
                      v-model="editmessage.content"
                      :config="editorConfig"
                      @input="onEditorInput"
                      @focus="onEditorFocus"
                      @blur="onEditorBlur"
                      @ready="onEditorReady"
                      @destroy="onEditorDestroy"
                    ></ckeditor>
                    <Field
                      name="content"
                      type="hidden"
                      class="form-control"
                      placeholder="本文を入力してください"
                      :class="{ 'is-invalid': errors.content }"
                      v-model="editmessage.content"
                      :rules="isRequired"
                    />
                    <ErrorMessage class="each_msg" name="content" />

                    <!-- <div class="error-color">{{ Validation.messageReult_content }}</div> -->
                  </div>
                </div>
                <div v-if="messagecolumn['content_1']" class="wrap wid_100per">
                  <p class="ttl_03">
                    {{ messagecolumn["content_1"] }}
                  </p>
                  <div class="in_wrap">
                    <ckeditor
                      :editor="editor"
                      v-model="editmessage.content_1"
                      :config="editorConfig"
                      @input="onEditorInput"
                      @focus="onEditorFocus"
                      @blur="onEditorBlur"
                      @ready="onEditorReady"
                      @destroy="onEditorDestroy"
                    ></ckeditor>
                    <Field
                      name="content_1"
                      type="hidden"
                      class="form-control"
                      placeholder="本文を入力してください"
                      v-model="editmessage.content_1"
                    />
                  </div>
                </div>

                <div v-if="messagecolumn['content_2'] && editmessage.content_2 !=''" class="wrap wid_100per" style="display:none">
                  <p class="ttl_03">
                    {{ messagecolumn["content_2"] }}
                  </p>
                  <div class="in_wrap">
                    <ckeditor
                      :editor="editor"
                      v-model="editmessage.content_2"
                      :config="editorConfig"
                      @input="onEditorInput"
                      @focus="onEditorFocus"
                      @blur="onEditorBlur"
                      @ready="onEditorReady"
                      @destroy="onEditorDestroy"
                    ></ckeditor>
                    <Field
                      name="content_2"
                      type="hidden"
                      class="form-control"
                      placeholder="本文を入力してください"
                      v-model="editmessage.content_2"
                    />
                  </div>
                </div>
                <div
                  v-if="messagecolumn['status']"
                  class="wrap wid_100per"
                  style="display: none"
                >
                  <p class="ttl_03">{{ messagecolumn["status"] }}</p>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        required=""
                        name="status"
                        as="select"
                        v-model="editmessage.status"
                        :rules="isRequired"
                      >
                        <option
                          v-if="messagecolumn['status_list'] == '投稿'"
                          value="下書き"
                        >
                          下書き
                        </option>
                        <option
                          v-if="messagecolumn['status_list'] == '投稿'"
                          value="公開"
                        >
                          公開
                        </option>
                        <option
                          v-if="messagecolumn['status_list'] == '承認'"
                          value="申請中"
                        >
                          申請中
                        </option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="status" />
                  </div>
                </div>
                <div
                  v-if="messagecolumn['published_at']"
                  class="wrap wid_100per"
                >
                  <p class="ttl_03">
                    {{ messagecolumn["published_at"] }}
                    <ErrorMessage class="each_msg" name="published_at" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="published_at"
                      type="datetime-local"
                      class="form-control"
                      v-model="editmessage.published_at"
                      value="editmessage.published_at"
                    />
                  </div>
                </div>
                <div
                  v-if="messagecolumn['attached_file']"
                  class="wrap wid_100per"
                >
                  <p class="ttl_03">
                    {{ messagecolumn["attached_file"]
                    }}<span
                      class=""
                      id="fileSize"
                      style="font-size: 1.4rem; margin-left: 0.5em"
                      >※アップロードできるファイルはpdfファイルです。</span
                    >
                    <ErrorMessage class="each_msg" name="attached_file" />
                  </p>
                  <div
                    v-if="editmessage.attached_file"
                    class="in_wrap"
                    id="attached_area"
                  >
                    <div
                      v-for="file in editmessage.attached_file"
                      :key="file.id"
                      class="attached_wrap"
                    >
                      <div class="attached_name_btn">
                        <input
                          name="rel_doc_title[]"
                          type="text"
                          class="fileName form-control"
                          required
                          placeholder="例：今月のスケジュールについて"
                          :value="file.display_name"
                          :data-id="file.id"
                        />
                      </div>
                      <div class="attached_fire_btn">
                        <a
                          v-on:click="dlfile(file.storage_file_name)"
                          class="en"
                          target="_blank"
                          :download="file.updaload_name"
                          ><i class="far fa-file-pdf"></i
                          >{{ file.updaload_name }}</a
                        >
                      </div>
                      <div class="attached_action_btn">
                        <div class="add_task_link task_link_btn">
                          <i class="fas fa-plus"></i>
                        </div>
                        <div
                          @click="delete_file(file.id)"
                          class="delete_task_link task_link_btn"
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="in_wrap" id="attached_area">
                    <div class="attached_wrap">
                      <div class="attached_name_btn">
                        <input
                          name="rel_doc_title[]"
                          type="text"
                          class="fileName form-control"
                          required
                          placeholder="例：今月のスケジュールについて"
                        />
                      </div>
                      <div class="attached_fire_btn">
                        <label>
                          <input
                            type="file"
                            name="rel_doc[]"
                            class="input-file form-control"
                            accept=".pdf"
                          />
                          <span class="file_name">添付ファイル</span>
                        </label>
                      </div>
                      <div class="attached_action_btn">
                        <div class="add_task_link task_link_btn">
                          <i class="fas fa-plus"></i>
                        </div>
                        <div
                          class="delete_task_link task_link_btn"
                          tabindex="-1"
                          data-target=""
                        >
                          <i class="fas fa-minus"></i>
                        </div>
                      </div>
                      <div class="fileDisplayName"></div>
                    </div>
                  </div>
                </div>
                <div v-if="message_type == 'thanks'" class="wrap wid_100per">
                  <p class="ttl_03">
                    部門 <ErrorMessage class="each_msg" name="thanks_group" />
                  </p>
                  <div class="in_wrap">
                    <div class="select_wrap" style="width: 260px">
                      <Field
                        required=""
                        name="thanks_group"
                        as="select"
                        v-model="editmessage.thanks_group"
                        @change="GrouponChange()"
                      >
                        <option
                          v-for="group_list in this.all_group.slice()"
                          v-bind:value="group_list.id"
                          :key="group_list.id"
                        >
                          {{ group_list.name }}
                        </option>
                      </Field>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    messagecolumn['target_ids'] &&
                    userlists &&
                    message_type != 'news' &&
                    message_type != 'photolog' &&
                    message_type != 'column' &&
                    approval_pass == false &&
                    is_approval_required==true
                  "
                  class="wrap wid_100per"
                >
                  <p class="ttl_03">
                    {{ messagecolumn["target_ids"] }}
                    <ErrorMessage class="each_msg" name="target_ids" />
                  </p>
                  <div class="in_wrap">
                    <div
                      v-if="
                        messagecolumn['status_list'] == '投稿' &&
                        message_type != 'daily'
                      "
                      class="select_wrap multi"
                      style="width: 260px"
                    >
                      <div class="searchInput">
                        <input
                          type="text"
                          @keyup="searchGroupUser($event)"
                          placeholder="ユーザーを絞り込み"
                        />
                      </div>
                      <Field
                        required=""
                        name="target_ids"
                        as="select"
                        v-model="editmessage.target_ids"
                        v-if="message_type != 'daily'"
                        :rules="isRequiredArr"
                        @change="setAthesekiUser($event)"
                        multiple
                      >
                        <template
                          v-for="userlist in this.userlists.slice()"
                          :key="userlist.id"
                        >
                          <option
                            :selected="
                              editmessage.target_ids.includes(userlist.id)
                            "
                            v-bind:value="userlist.id"
                          >
                            {{ userlist.last_name }}{{ userlist.first_name }}
                            {{
                              userlist.role ? " (" + userlist.role + ")" : ""
                            }}
                          </option>
                        </template>
                      </Field>

                      <Field
                        required=""
                        name="target_ids"
                        as="select"
                        v-model="editmessage.target_ids"
                        v-if="message_type == 'daily'"
                        @change="setAthesekiUser($event)"
                        multiple
                      >
                        <option
                          v-for="userlist in this.userlists.slice()"
                          v-bind:value="userlist.id"
                          :key="userlist.id"
                        >
                          {{ userlist.last_name }}{{ userlist.first_name }}
                          {{ userlist.role ? " (" + userlist.role + ")" : "" }}
                        </option>
                      </Field>
                    </div>
                    <div v-else class="select_wrap" style="width: 260px">
                      <Field
                        required=""
                        name="target_ids"
                        as="select"
                        v-model="editmessage.target_ids"
                        :rules="isRequiredArr"
                        @change="setAthesekiUser($event)"
                      >
                        <option disabled>未設定</option>
                        <option
                          v-for="userlist in this.groupUsers.slice()"
                          v-bind:value="userlist.id"
                          :key="userlist.id"
                        >
                          {{ userlist.last_name }} {{ userlist.first_name }}
                          {{ userlist.role ? " (" + userlist.role + ")" : "" }}
                        </option>
                      </Field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="t_c">
                <div class="btn_large">
                  <!-- <button
                    :disabled="processing"
                    type="submit"
                    class="btn btn-primary"
                  >
                    送信
                  </button> -->

                  <button
                    v-if="
                      message_type == 'column' ||
                      message_type == 'photolog' ||
                      message_type == 'sdgs' ||
                      message_type == 'challenge' ||
                      message_type == 'idea' ||
                      message_type == 'news'
                    "
                    :disabled="processing"
                    type="submit"
                    class="btn btn-primary"
                  >
                    承認申請
                  </button>
                  <button
                    v-else
                    :disabled="processing"
                    type="submit"
                    class="btn btn-primary"
                  >
                    送信
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
        <!-- <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
            >お知らせ</router-link
          >
        </li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >創発カード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
  <template id="item_doc_Template">
    <div class="attached_wrap new_item">
      <div class="attached_name_btn">
        <input
          name="rel_doc_title[]"
          type="text"
          class="fileName form-control"
          required
          placeholder="例：今月のスケジュールについて"
        />
      </div>
      <div class="attached_fire_btn">
        <label>
          <input
            type="file"
            name="rel_doc[]"
            class="input-file form-control"
            accept=".pdf"
          />
          <span class="file_name">添付ファイル</span>
        </label>
      </div>
      <div class="attached_action_btn">
        <div class="add_task_link task_link_btn">
          <i class="fas fa-plus"></i>
        </div>
        <div class="delete_task_link task_link_btn" tabindex="-1">
          <i class="fas fa-minus"></i>
        </div>
      </div>
      <div class="fileDisplayName"></div>
    </div>
  </template>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/select2/select2.min.js";
import $ from "jquery";
import message from "@/apis/Message";
import user from "@/apis/User";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import ClassicEditor from "ckeditor5-build-classic-with-base64-and-autolink/build/ckeditor";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import striptags from "striptags";
import Groups from "../../../../apis/Groups";
import SdgsGoalRatio from '../sdgs/SdgsGoalRatio';

export default {
  name: "message",
  data() {
    return {
      approval_pass: false,
      userlists: "",
      taglists: "",
      all_group: "",
      messages: [],
      sdgsGoaltitle: "",
      mySdgsTitleGoal1: "",
      mySdgsTitleGoal2: "",
      sdgsGoalMsgId: "",
      checkedGoal: false,
      editmessage: {
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        target_type: "users",
        type: this.$route.params.type,
        content_type: "HTML",
        thanks_type: [],
        published_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      processing: false,
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      isSdgsOtherType: true,
      messagetype: {},
      editor: ClassicEditor,
      editorConfig: {},
      editorValidation: true,
      old_fileid: "",
      before_thanks_type: [],
      attached_file: [],
      delete_attached_file: [],
      athesekiList: [],
      userlistsathesekiList: [],
      getAllGroupAthesekiUsers: [],
      profileImg: false,
      currentYear: +new Date().getFullYear(),
      editTargetIds: [],
      is_approval_required: true,
      personsRoleList: ['部長', '会長', '取締役副社長', '代表取締役社長', '取締役', '執行役員部長', '専務取締役'],
    };
  },
  computed: {},
  watch: {
    $route(to, from) {
      if (this.messagecolumn["tag_list"]) {
        this.getTagLists(this.messagecolumn["tag_list"]);
      }
    },
    userlists(val) {
      this.athesekiList = val;
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    SdgsGoalRatio
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = this.$route.params.type;
    this.messagecolumn = MessageColumn[this.message_type];
    this.messagetype = MessageType[this.message_type];
    this.message_id = this.$route.params.id;

    if (this.message_type == "thanks" || this.message_type == "daily") {
      this.getAllGroup();
    } else {
      this.getUserLists();
    }

    // Groups.getGroupUsersByGroupId(this.$User.group.id, this.message_type).then(
    //   (response) => {
    //     this.groupUsers = response.data.data;
    //     this.userlists = response.data.data;
    //   }
    // );

    if (this.$User.group) {
      //親のグループIDを取得
      var target_group_id_oya_daily = this.$User.group.id;

      if (
        this.$User.group.parent_id &&
        (this.message_type == "idea" ||
          this.message_type == "sdgs" ||
          this.message_type == "challenge")
      ) {
        target_group_id_oya_daily = this.$User.group.parent_id;
      } else {
        target_group_id_oya_daily = this.$User.group.id;
      }

      Groups.getGroupUsersByGroupId(
        target_group_id_oya_daily,
        this.message_type
      ).then((response) => {
        this.groupUsers = response.data.data;
      });
    }

    this.getAthesekiUser();
    if (this.messagecolumn["tag_list"]) {
      this.getTagLists(this.messagecolumn["tag_list"]);
    }
    this.profileImgApi();

    this.editmessageView(this.message_id);

    //承認不要チェック
    if (
      // MFUX-1444  お知らせ一覧で投稿者の部門がない時のローディングイメージをなくす
      this.$User.group &&
      (this.message_type == "column" || this.message_type == "photolog")
    ) {
      if (this.$User.group.name == "経営企画室") {
        this.approval_pass = true;
      }

      if (
        this.$User.group.group_role == "執行役員部長" ||
        this.$User.group.group_role == "部長"
      ) {
        this.approval_pass = true;
      }
    }

if(this.message_type=='sdgs' || this.message_type=='challenge')
  {
    switch(this.$User.group.group_role) 
        {
          case '部長':
          case '会長':
          case '取締役副社長':
          case '代表取締役社長':
          case '取締役':
          case '執行役員部長':
          case '専務取締役':
            this.is_approval_required=false;
          break;
        
          default:
            // code block
        } 
  }
   
    // if(this.messagecolumn['target_ids'] && this.message_type != 'daily'){
    // 	this.editmessage.target_type = "users";
    // }
    if (this.messagecolumn["status_list"] == "投稿") {
      this.editmessage.status = "公開";
    } else if (this.messagecolumn["status_list"] == "承認") {
      if (this.approval_pass == false) {
        this.editmessage.status = "下書き";
      } else if (this.approval_pass == true) {
        if (this.message_type != "news") {
          this.editmessage.status = "公開";
        } else {
          this.editmessage.status = "下書き";
        }
      }
    }
  },
  mounted() {
    let vueThis = this;

    document.body.className = "page_control page_template page_sdgs";

    vueThis.message_type = vueThis.$route.params.type;

    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
    $(function () {
      $(".target_user_select").select2({
        width: "260px",
        language: {
          noResults: function () {
            return "登録されていません";
          },
        },
        placeholder: "宛先のユーザーを選択",
        allowClear: true,
        multiple: true,
      });
    });
    $(document).on("change", ".input-file", function (e) {
      var $el = $(e.currentTarget);
      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");
      var $filesSelectName = $el
        .closest(".attached_wrap,.new_item")
        .find(".file_name");

      if (e.target.files[0].size > 3000000) {
        $("#fileSize").text("2 mb以下のファイルを指定してください。");
        $("#fileSize").addClass("each_msg");
        return;
      } else {
        $("#fileSize").text("※アップロードできるファイルはpdfファイルです。");
        $("#fileSize").removeClass("each_msg");
      }

      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");

      $($filesSelectName).text(e.target.files[0].name);

      if ($fileName.val() == "") {
        if (e.target.files[0].name) {
          $fileName.val(e.target.files[0].name);
        } else {
          var today = new Date();
          $fileName.val(today + e.target.files[0].type);
          // $($fileDisplayName).html("資料名を入力してください");
          // $($fileDisplayName).addClass('each_msg')
        }
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });

    $(document).on("change", ".fileName", function (e) {
      var $el = $(e.currentTarget);
      var $fileData = $el
        .closest(".attached_wrap,.new_item")
        .find(".input-file");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");
      if ($el.val() == "") {
        $($fileDisplayName).html("資料名を入力してください");
        $($fileDisplayName).addClass("each_msg");
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });
    $(document).on("click", ".add_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $itembox = $el.closest(".attached_wrap,.new_item");
      var htmlString = $("#item_doc_Template").html();
      $itembox.after(htmlString);
    });
    $(document).on("click", ".delete_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $row = $el.closest(".attached_wrap,.new_item");
      $row.remove();

      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");

      for (var i = 0; i < vueThis.attached_file.length; i++) {
        if (vueThis.attached_file[i].display_name == $fileName.val()) {
          vueThis.attached_file.splice(i, 1);
          break;
        }
      }
      return false;
    });
    $(document).on("blur", ".fileName", function (e) {
      var $el = $(e.currentTarget);
      $el[0].dataset.value = $el.val();
    });
  },
  methods: {
    GrouponChange() {
      this.searchGroupId = this.editmessage.thanks_group;

      if (this.message_type == "thanks" || this.message_type == "daily") {
        Groups.getGroupUsersByGroupId(
          this.editmessage.thanks_group,
          this.message_type
        ).then((response) => {
          this.userlists = response.data.data;
        });
      } else {
        this.getUserLists();
      }
    },
    goalCheckBox(id) {
      if(id === 1){
        this.sdgsGoaltitle = this.mySdgsTitleGoal1;
        this.checkedGoal = true;
        this.isSdgsOtherType = true
      }
      if(id === 2) {
        this.sdgsGoaltitle = this.mySdgsTitleGoal2;
        this.checkedGoal = true;
        this.isSdgsOtherType = true
      }
      if(id === 3) {
        this.sdgsGoaltitle = "その他";
        this.checkedGoal = false;
        this.isSdgsOtherType = false
      }
    },
    getAllGroup() {
      user
        .group_list()
        .then((response) => {
          if (response != null) {
            this.all_group = response.data.data;
            this.getAllGroupByChildId = response.data.data;

            this.all_group = response.data.data.filter((item) => {
              return item.parent_id == null && item.name != "開発グループ";
            });

            if (this.message_type == "thanks" || this.message_type == "daily") {
              Groups.getGroupUsersByGroupId(
                this.$User.group.id,
                this.message_type
              ).then((response) => {
                this.userlists = response.data.data;
              });
            } else {
              this.getUserLists();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    setAthesekiUser(e) {
      if (!this.editmessage.target_ids.includes(e.target.value)) {
        this.editmessage.target_ids.push(e.target.value);
      }
      console.log(this.editmessage.target_ids);
    },
    getAthesekiUser() {
      if (this.$User.group)
        Groups.getGroupUsersByGroupId(
          this.$User.group.id,
          this.message_type
        ).then((response) => {
          this.getAllGroupAthesekiUsers = response.data.data;
        });
    },
    onEditorInput(data, event, editor) {
      editor.editing.view.document.on(
        "enter",
        (evt, data) => {
          if (data.isSoft) {
            editor.execute("enter");
          } else {
            editor.execute("shiftEnter");
          }

          data.preventDefault();
          evt.stop();
          editor.editing.view.scrollToTheSelection();
        },
        { priority: "high" }
      );
    },
    onEditorFocus(event, editor) {
      if (this.message_type == "sdgs" || this.message_type == "challenge") {
        editor.ui.view.editable.element.style.height = "170px";
      } else {
        editor.ui.view.editable.element.style.height = "450px";
      }
    },
    onEditorBlur(event, editor) {
      if (this.message_type == "sdgs" || this.message_type == "challenge") {
        editor.ui.view.editable.element.style.height = "170px";

        setTimeout(() => {
          editor.ui.view.editable.element.style.height = "170px";
        }, 10);
      } else {
        editor.ui.view.editable.element.style.height = "450px";
      }
    },
    onEditorReady(editor) {
      if (this.message_type == "sdgs" || this.message_type == "challenge") {
        editor.ui.view.editable.element.style.height = "170px";
      } else {
        editor.ui.view.editable.element.style.height = "450px";
      }
    },
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                    //console.log(this.profileImg);
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    delete_file(file_id) {
      this.delete_attached_file.push(file_id);
    },
    isRequired(value, name) {
      if (value) {
        return true;
      }
      var field_name = this.messagecolumn[name.field];
      return field_name + "は必須です";
    },
    isRequiredArr(value, name) {
      var field_name = this.messagecolumn[name.field];

      if (value == undefined || value == "" || value == "未設定") {
        return field_name + "は必須です";
      }

      if (value != "") {
        return true;
      } else {
        return field_name + "は必須です";
      }
    },
    getSdgsReportGoal(goalType, msgId){
      user.userPostedSdgsReport(msgId).then((res)=>{

        let decodedContent = JSON.parse(res.data.message.content)
        this.mySdgsTitleGoal1 = striptags(decodedContent.content)
        this.mySdgsTitleGoal2 = striptags(decodedContent.content_1)

        let currentGoal = this.taglists.find((el)=> el.name == goalType)
              
        if (currentGoal.id == 1) {
          this.sdgsGoaltitle = this.mySdgsTitleGoal1
        }else if(currentGoal.id == 2){
          this.sdgsGoaltitle = this.mySdgsTitleGoal2
        }else if(currentGoal.id == 3){
          this.sdgsGoaltitle = currentGoal.name
          this.isSdgsOtherType = false
        }
      }).catch((err)=>{
        console.log('err',err);
      })
    },
    editmessageView(id) {
      this.loader = true;
      message
        .edit(id)
        .then((response) => {
          if (response != null) {
            this.editmessage = response.data.data;

            if (this.message_type != "daily" && this.message_type != "thanks") {
              this.editTargetIds = response.data.data.target_ids;
              this.editmessage.target_ids = response.data.data.target_ids[0];
            }

            if (this.editmessage.from_id != this.$User.id) {
              this.$router.push({ name: "Front 404" });
            }

            this.message_type = this.$route.params.type;

            if (
              this.message_type == "sdgs" ||
              this.message_type == "challenge" || this.message_type == "mysdgs"
            ) {
              let contentSplit = JSON.parse(response.data.data.content);
              this.sdgsGoalMsgId = contentSplit.messageId;

              this.getSdgsReportGoal(contentSplit.goalType, this.sdgsGoalMsgId);

              this.editmessage.content = contentSplit.content;

              this.editmessage.content_1 = contentSplit.content_1;

              this.editmessage.content_2 = contentSplit.content_2;

              this.editmessage.sdgs_option = contentSplit.option;

              this.currentYear = contentSplit.option;

              this.editmessage.month = contentSplit.month;
            }

            this.getMessageMeta(id);
            this.getMessageEyecatch(id);
            this.getMessageAttachedFile(id);
            this.editmessage.published_at = this.format_date(
              this.editmessage.published_at
            );
            if (this.messagecolumn["published_at"]) {
              this.editmessage.published_at =
                this.editmessage.published_at.replace(" ", "T");
            } else {
              this.editmessage.published_at += ":00";
            }
            this.editmessage.tags = this.editmessage.tags.map(
              (item) => item["id"]
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    update(id) {
      this.processing = true;
      setTimeout(this.enable, 3000);

      if (this.editmessage.target_ids) {
        if (this.editmessage.target_ids.length) {
          this.editmessage.target_type = "users";
        } else {
          this.editmessage.target_type = null;
        }
      }else{
        this.editmessage.target_ids = []
      }

      this.loader = true;
      // Remove for publish_at set default value MFUX-991 - フロント：メッセージ系の編集画面でステータス編集が不要
      // if (this.messagecolumn["published_at"] && this.editmessage.published_at) {
      //   this.editmessage.published_at = this.editmessage.published_at.replace("T", " ");
      //   this.editmessage.published_at += ":00";
      // }

      if (
        this.message_type == "sdgs" ||
        this.message_type == "challenge" ||
        this.message_type == "idea" ||
        this.message_type == "news"
      ) {
        this.editmessage.status = "申請中";
      }
      if (
        this.is_approval_required == false
      ) {
        this.editmessage.status = "公開";
      }

      if(this.editmessage.target_ids == this.editmessage.from_id){
        this.editmessage.target_type = null;
        this.editmessage.status = '承認済';
      }
      

      if (this.messagecolumn["attached_file"]) {
        for (var i = 0; i < $("#attached_area .attached_wrap").length; i++) {
          let $el = $(".attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");

          let $fileData = $el.find(".input-file");
          if ($fileData[0] != undefined) {
            if (!($fileName.val() == "" && $fileData[0].files.length == 0)) {
              if ($fileName.val() == "") {
                alert("資料名を入力してください");
                this.attached_file = [];
                return;
              }
              if ($fileData[0].files.length == 0) {
                alert("ファイルを選択してください");
                this.attached_file = [];
                return;
              }
              if (file && permit_type.indexOf(file.type) == -1) {
                alert("アップロードできるファイルの形式はpdfです");
                this.attached_file = [];
                return;
              }

              let fileObject = new FormData();

              fileObject.append("key", "attached_file");
              fileObject.append("display_name", $fileName.val());
              fileObject.append("uploadfile", $fileData[0].files[0]);

              this.attached_file.push(fileObject);

              var file = $fileData[0].files[0];
              var permit_type = ["application/pdf"];
            }
          } else {
            let $fileId = $el.find(".fileName").attr("data-id");
            let $fileName_change = $el.find(".fileName").attr("data-value");
            let put_file = "";
            if ($fileName_change != null) {
              put_file = {
                key: "attached_file",
                display_name: $fileName_change,
              };
            } else {
              put_file = {
                key: "attached_file",
                display_name: $fileName.val(),
              };
            }
            this.putMessageAttachedfile(put_file, id, $fileId);
          }
        }
      }

      this.editmessage.published_at = moment(new Date()).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      $("#js-loader").fadeIn();

      if (this.message_type == "sdgs" || this.message_type == "challenge") {
        this.editmessage.content = JSON.stringify({
          content: this.editmessage.content,
          content_1: this.editmessage.content_1,
          content_2: this.editmessage.content_2,
          option: this.editmessage.sdgs_option,
          // year: this.editmessage.sdgs_option,
          month: this.editmessage.month,
        });
      }

      if (
        this.message_type != "daily" &&
        this.message_type != "thanks" &&
        this.message_type != "idea" &&
        this.message_type != "challenge" &&
        this.message_type != "sdgs"
      ) {
        this.editmessage.target_ids = this.editTargetIds;
      }

      if (this.approval_pass == false) {
        if (this.message_type == "column" || this.message_type == "photolog") {
          this.editmessage.status = "申請中";
          this.editmessage.tags = [];
        }
      }

      if (Array.isArray(this.editmessage.target_ids)) {
        this.editmessage.target_ids = this.editmessage.target_ids.filter(
          (item) => {
            return item != this.$User.id;
          }
        );
      } else {
        var idea_target_id = this.editmessage.target_ids;
        this.editmessage.target_ids = null;
        this.editmessage.target_ids = [idea_target_id];
      }

      if (this.message_type == 'mysdgs') {

        let selectedGoalType = this.taglists.find((el)=> el.id == this.editmessage.tags);

        let contentObj = JSON.stringify({
          goalType: selectedGoalType.name,
          content: this.editmessage.content,
          messageId: this.sdgsGoalMsgId
        });

        this.editmessage.content = contentObj;
        this.editmessage.tags = [selectedGoalType.id];
        this.editmessage.title = this.sdgsGoaltitle;
      }
      
      message
        .update(this.editmessage, id)
        .then(
          (response) => {
            this.putMessageMeta(id);
            if (this.attached_file.length > 0) {
              this.postMessageAttachedfile(response.data.data.id);
            }
            if (this.delete_attached_file.length > 0) {
              this.delete_attached_file.forEach((file_id) => {
                this.deleteAttachedfile(id, file_id);
              });
            }
            $("#js-loader").fadeOut();
            this.$router.push({
              name: "Control messageshow",
              params: { type: this.message_type, id: this.message_id },
            });
          },
          (error) => {
            console.log(error);
            this.loader = false;
          }
        )
        .catch(() => {})
        .finally(() => {});
    },
    async getUserLists() {
      console.log('this.messagecolumn["status_list"]');
      console.log(this.messagecolumn["status_list"]);
      if (this.messagecolumn["status_list"] == "投稿") {
        if (
          this.editmessage.thanks_group == null &&
          this.$User.group.id == null
        ) {
          user
            .list()
            .then((response) => {
              if (response != null) {
                this.userlists = response.data.data;
              }
            })
            .catch((error) => {
              console.log(error);
              this.$router.push({ name: "Front Error Catch" });
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          if (this.editmessage.thanks_group == null) {
            if (this.$User.group.parent_id == null) {
              this.editmessage.thanks_group = this.$User.group.id;
            } else {
              this.editmessage.thanks_group = this.$User.group.parent_id;
            }
          }

          // console.log(this.getAllGroupByChildId);

          let target_user_data = [];

          let allChildIds = this.getAllGroupByChildId
            .filter((item) => {
              return item.parent_id == this.editmessage.thanks_group;
            })
            .map(function (item) {
              return item.id;
            });

          allChildIds.push(this.editmessage.thanks_group);

          for (let index = 0; index < allChildIds.length; index++) {
            await user
              .get_group(allChildIds[index])

              .then(async (response) => {
                if (response != null) {
                  if (response.data.data.group_users.length > 0) {
                    response.data.data.group_users.forEach((user_data) => {
                      user
                        .show(user_data.user_id)
                        .then((response) => {
                          if (response != null) {
                            if (this.message_type == "daily") {
                              if (response.data.data.group) {
                                if (
                                  response.data.data.group.group_role != null
                                ) {
                                  target_user_data.push(response.data.data);
                                }
                              }
                            } else {
                              target_user_data.push(response.data.data);
                            }
                            // TEST
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                        .finally(() => {
                          this.loader = false;
                        });
                    });

                    this.userlists = target_user_data;
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {});
          }
        }
      } else if (this.messagecolumn["status_list"] == "承認") {
        //親のグループIDを取得
        var target_group_id = null;

        if (this.$User.group) {
          if (this.$User.group.parent_id) {
            target_group_id = this.$User.group.parent_id;
          } else {
            target_group_id = this.$User.group.id;
          }
        }
        //console.log(target_group_id);

        //属するグループリスト作成
        var target_group_array = [];
        Groups.list()
          .then((response) => {
            if (response != null) {
              //console.log(response.data.data);
              response.data.data.forEach((ob) => {
                //親のグループ
                if (ob.id == target_group_id) {
                  target_group_array.push(ob);
                }
                //属する課
                if (ob.parent_id == target_group_id) {
                  target_group_array.push(ob);
                }
              });
              var target_user_data = [];
              var target_user_ids = [];
              target_group_array.forEach((under_ob) => {
                user
                  .get_group(under_ob.id)
                  .then((response) => {
                    if (response != null) {
                      //console.log(response.data.data);

                      if (response.data.data.group_users.length > 0) {
                        response.data.data.group_users.forEach((user_data) => {
                          if (
                            this.message_type == "photolog" ||
                            this.message_type == "column"
                          ) {
                            if (
                              user_data.role != null &&
                              user_data.rank > 299
                            ) {
                              user
                                .show(user_data.user_id)
                                .then((response) => {
                                  if (response != null) {
                                    target_user_data.push(response.data.data);
                                    target_user_ids.push(response.data.data.id);
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                })
                                .finally(() => {
                                  this.loader = false;
                                });
                            }
                            this.editmessage.target_ids = target_user_ids;
                          } else if (this.message_type == "news") {
                            this.getNewsApproval();
                          } else {
                            if (user_data.role != null) {
                              user
                                .show(user_data.user_id)
                                .then((response) => {
                                  if (response.data) {
                                    target_user_data.push(response.data.data);
                                    target_user_ids.push(response.data.data.id);
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                })
                                .finally(() => {
                                  this.loader = false;
                                });
                            }
                          }
                        });
                        this.userlists = target_user_data;
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    this.$router.push({ name: "Front Error Catch" });
                  })
                  .finally(() => {
                    this.loader = false;
                  });
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },
    getTagLists(tag_array) {
      if (this.message_type == "mysdgs") {
        console.log('tagss',tag_array, this.taglists);
        this.taglists = tag_array;
      }else{
        message
          .tag_list(tag_array)
          .then((response) => {
            if (response != null) {
              this.taglists = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD hh:mm");
      }
    },
    getMessageMeta(message_id) {
      if (this.messagecolumn["sdgs_type"]) {
        message
          .showmetakey(message_id, "key", "sdgs_type")
          .then((response) => {
            if (response != null) {
              this.editmessage.sdgs_type = response.data.data[0].value;
              this.editmessage.sdgs_id = response.data.data[0].id;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["group"]) {
        message
          .showmetakey(message_id, "key", "group")
          .then((response) => {
            if (response != null) {
              this.editmessage.group = response.data.data[0].value;
              this.editmessage.group_id = response.data.data[0].id;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["public"]) {
        message
          .showmetakey(message_id, "key", "public")
          .then((response) => {
            if (response != null) {
              this.editmessage.public = response.data.data[0].value;
              this.editmessage.public_id = response.data.data[0].id;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["thanks_type"]) {
        this.editmessage.thanks_type = [];
        message
          .showmetakey(message_id, "key", "thanks_type")
          .then((response) => {
            if (response != null) {
              response.data.data.forEach((thanks_type) => {
                this.editmessage.thanks_type.push(thanks_type.value);
              });
              this.before_thanks_type = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
    putMessageMeta(message_id) {
      if (this.messagecolumn["sdgs_type"]) {
        var post_json = {
          key: "sdgs_type",
          value: this.editmessage.sdgs_type,
          description: "SDGsタイプ",
        };
        message
          .update_meta(post_json, message_id, this.editmessage.sdgs_id)
          .then(
            (response) => {
              console.log(response);
              // this.$router.push({ name: 'Control message', params: { type: (this.message_type) }});
            },
            (error) => {
              console.log(error);
              // this.$router.push({ name: 'Front Error' });
            }
          )
          .catch((error) => {
            console.log(error);
            // this.$router.push({ name: 'Front Error Catch' });
          })
          .finally(() => {});
      }
      if (this.messagecolumn["group"] && this.editmessage.group) {
        var post_json_group = {
          key: "group",
          value: this.editmessage.group,
          description: "部門",
        };
        message
          .update_meta(post_json_group, message_id, this.editmessage.group_id)
          .then(
            (response) => {
              console.log(response);
            },
            (error) => {
              console.log(error);
              this.$router.push({ name: "Front Error" });
            }
          )
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {});
      }
      if (this.messagecolumn["public"]) {
        var post_json_public = {
          key: "public",
          value: this.editmessage.public,
          description: "一般公開設定",
        };
        message
          .update_meta(post_json_public, message_id, this.editmessage.public_id)
          .then(
            (response) => {
              console.log(response);
            },
            (error) => {
              console.log(error);
              this.$router.push({ name: "Front Error" });
            }
          )
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {});
      }
      if (this.messagecolumn["thanks_type"]) {
        if (this.before_thanks_type) {
          this.before_thanks_type.forEach((before_type) => {
            message
              .delete_meta(message_id, before_type.id)
              .then(
                (response) => {
                  console.log(response);
                },
                (error) => {
                  console.log(error);
                  this.$router.push({ name: "Front Error" });
                }
              )
              .catch((error) => {
                console.log(error);
                this.$router.push({ name: "Front Error Catch" });
              })
              .finally(() => {});
          });
        }
        if (this.messagecolumn["thanks_type"]) {
          this.editmessage.thanks_type.forEach((thanks_type) => {
            let post_json_thanks_type = {
              key: "thanks_type",
              value: thanks_type,
              description: "サンクスタイプ",
            };
            message
              .register_meta(post_json_thanks_type, message_id)
              .then(
                (response) => {
                  console.log(response);
                },
                (error) => {
                  console.log(error);
                  this.$router.push({ name: "Front Error" });
                }
              )
              .catch((error) => {
                console.log(error);
                this.$router.push({ name: "Front Error Catch" });
              })
              .finally(() => {});
          });
        }
      }
    },
    getMessageEyecatch(message_id) {
      if (this.messagecolumn["photolog"]) {
        message
          .showfile(message_id, "key", "photolog")
          .then((response) => {
            if (response != null) {
              this.editmessage.photolog = response.data.data[0];
              this.old_fileid = response.data.data[0].id;
              let fileApiPass =
                "/api/v1/file/" + this.editmessage.photolog.storage_file_name;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then((response) => {
                  let blob = new Blob([response.data], {
                    type: response.headers["content-type"],
                  });
                  let img = document.getElementById("img_tag_id");
                  let url = window.URL || window.webkitURL;
                  img.src = url.createObjectURL(blob);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
    putMessagefile(key, display_name, id) {
      if (this.messagecolumn["photolog"]) {
        if (this.editmessage.photolog[0]) {
          message
            .file_delete(id, this.old_fileid)
            .then(
              (response) => {
                console.log(response);
              },
              (error) => {
                console.log(error);
                this.$router.push({ name: "Front Error" });
              }
            )
            .catch((error) => {
              console.log(error);
              this.$router.push({ name: "Front Error Catch" });
            })
            .finally(() => {});

          let fileObject = new FormData();
          fileObject.append("key", key);
          fileObject.append("display_name", display_name);
          fileObject.append("uploadfile", this.editmessage.photolog[0]);

          message
            .register_file(fileObject, id)
            .then(
              (response) => {
                console.log(response);
              },
              (error) => {
                console.log(error);
                this.$router.push({ name: "Front Error" });
              }
            )
            .catch((error) => {
              console.log(error);
              this.$router.push({ name: "Front Error Catch" });
            })
            .finally(() => {});
        }
      }
    },
    getMessageAttachedFile(message_id) {
      if (this.messagecolumn["attached_file"]) {
        message
          .showfile(message_id, "key", "attached_file")
          .then((response) => {
            if (response.data.data.length != 0) {
              this.editmessage.attached_file = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
    dlfile(path) {
      this.windowReference = window.open();
      //let fileApiPass = '/api/v1/file/adedd8819fecb6f2abd2756a7771803d?attachment';
      let fileApiPass = "/api/v1/file/" + path;
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          this.windowReference.location = window.URL.createObjectURL(blob);
        });
    },
    enable: function () {
      this.processing = false;
    },
    async postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.attached_file.length; i++) {
        message
          .register_file(this.attached_file[i], createdId)
          .then(
            (response) => {
              if (i == this.attached_file.length - 1) {
                if (response != "") {
                  console.log(response);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .catch(() => {})
          .finally(() => {});
      }
    },
    putMessageAttachedfile(put_file, id, $fileId) {
      message
        .update_file(put_file, id, $fileId)
        .then(
          (response) => {
            console.log(response);
            // this.$router.push({ name: 'Control message', params: { type: (this.message_type) }});
          },
          (error) => {
            console.log(error);
            // this.$router.push({ name: 'Front Error' });
          }
        )
        .catch((error) => {
          console.log(error);
          // this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {});
    },
    deleteAttachedfile(id, file_id) {
      message
        .file_delete(id, file_id)
        .then(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    changeYear: function (event) {
      if (event.target.value != "") {
        this.currentYear = event.target.value;
      } else {
        this.currentYear = "";
      }
    },
    changeMonth: function (e) {
      this.editmessage.sdgs_option =
        e.target.options[e.target.options.selectedIndex].dataset.year;

      this.editmessage.month = e.target.value;

      if (this.editmessage.sdgs_option == new Date().getFullYear() - 1) {
        this.editmessage.sdgs_option = new Date().getFullYear();
      }
    },
    getNewsApproval() {
      user
        .get_news_approval()
        .then((response) => {
          if (response != null) {
            // console.log(response.data.data.id);
            // if (response.data.data.id != this.$User.id) {
            //   }
            this.editmessage.target_ids = response.data.data.id;
            // this.getAllGroupByChildId = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
